import React from 'react'

export default function MyInformation() {
    return (
        <div className="my-info">
            <span className="contact-info">
                <a href="mailto:evan.woodworth@gmail.com">evan.woodworth@gmail.com</a>{' | 425-417-7360 | '}<a href="https://www.linkedin.com/in/evan-woodworth/">LinkedIn</a>
            </span>
            <div className="my-name">Evan Woodworth</div>
        </div>
    )
}
