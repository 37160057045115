import React from 'react'

export default function MySummary() {
    return (
        <div className="summary-container">
            <div className="summary-header">Evan Woodworth</div>
            <div className="summary-subheader">A Full-Stack Software Engineer based in Seattle</div>
            <div className="summary-text"> &emsp; I am a solution-oriented software engineer with a passion for creative problem solving and innovation. I pride myself in my ability to think outside the box and create simple solutions to complex problems. My extensive experience with customer-facing projects and services has taught me to keep an open mind towards feedback or new ideas, guiding my team towards the best possible approach to make the customer happy.</div>
        </div>
    )
}
