import React from 'react'
import ProjectShow from './ProjectShow'

export default function ProjectContainer(props) {
    const theProjects = props.projects.map( (project, idx) => (
        <ProjectShow key={idx} project={project} technologies={props.technologies} />
    ))

    return (
        <div className="project-container">
            {theProjects}
        </div>
    )
}
