import React, { useState } from 'react';

export default function Technology(props) {
    const [hovered, setHovered] = useState(false);
    const technology = props.technology;
    const onHover = (e) => {
        setHovered(true);
    }

    const offHover = () => {
        setHovered(false);
    }

    return (
        <div className="technology-show">
            <a href={props.technologies[technology].link}  
            onMouseEnter={e=>onHover(e)}
            onMouseLeave={offHover}>{technology}</a>
            <div className="hover-space">
                { hovered ?
                <div className={"tech-hover tech-" + technology}>{props.technologies[technology].description}</div>
                : <></> }
            </div>
        </div>
    )
}
