import React, { useState } from 'react';
import TechnologyShow from './TechnologyShow';

export default function ProjectShow(props) {
    const { title, technologies, deployedLink, githubLink,
        description, coverImage, actionShot } = props.project;

    const [projectHover, setProjectHover] = useState(false);
    const [projectDisplay, setProjectDisplay] = useState(false);

    const technologyList = technologies.map((technology, idx)=>(
        <div key={idx}>
            <TechnologyShow technology={technology} technologies={props.technologies}/>
        </div>
        
    ))

    const onProjectHover = () => {
        setProjectHover(true);
    }

    const offProjectHover = () => {
        setProjectHover(false);
    }

    const handleProjectDisplay = () => {
        if (projectDisplay) {
            setProjectDisplay(false);
        } else {
            setProjectDisplay(true);
        }
    }

    return (
        <div className="project-show" onClick={handleProjectDisplay} onMouseEnter={onProjectHover} onMouseLeave={offProjectHover}>
            <h1 className="project-title">{title}</h1>
            {
                projectDisplay ?
                <div className='project-info'>
                    <div className="technology-list">{technologyList}</div>
                    <div className="project-description">
                        {description}
                        <a href={deployedLink}>Deployed Link</a>
                        <a href={githubLink}>Code Repository</a>
                    </div>
                </div>
                :
                <img alt={title} className="project-image" src={ projectHover ? actionShot : coverImage }/>
            }
        </div>
    )
}
